import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'

export default class DefaultPageRenderer extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Tool`)
    if (isDEVMODE) console.log(viewStorage.current)

    let table = new DataTable('#myTable', {
      // options
    });
  }

  // onEnterCompleted() {}

  // onLeave() {}

  // onLeaveCompleted() {}
}
