import { isDEVMODE, viewStorage } from '../_globals'
import { gsap } from 'gsap'
import Slugify from 'slugify'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export default class SingleAside {

  constructor(aside) {

    this.DOM = {
      aside: aside,
    }

    if(this.DOM.aside) {
      this.DOM.asideNav = this.DOM.aside.querySelector('.Single__nav')
      this.init()
    }

  }

  init() {

    if (isDEVMODE) console.log('init SingleNav')

    this.createNav()
    this.addEvents()

  }

  createNav() {
    const { asideNav } = this.DOM
    const { hasSmoothScroll } = viewStorage

    // H2

    ScrollTrigger.defaults({
      scroller: window,
      horizontal: this.isHorizontal,
      markers: isDEVMODE,
      scrub: true
    })

    const h2s = viewStorage.current.querySelectorAll('.Single__content h2')
    this.h2s = []
    this.updateNav = this.updateNav.bind(this)

    h2s.forEach((h2, index) => {

      // Create Link
      const slug = Slugify(h2.innerHTML, { lower: true, remove: /[*+~.()'"!:@,?/]/g })
      console.log(h2, slug)
      h2.setAttribute('id', `${slug}`)
      const linkTemplate = `
      <a href="#${slug}">
        <span>
          ${h2.innerHTML}
        </span>
      </a>
      `
      asideNav.insertAdjacentHTML('beforeend', linkTemplate)

      // Create ST
      h2.ST = ScrollTrigger.create({
        trigger: h2,
        toggleClass: 'is--visible',
        start: 'top center',
        onEnter: () => this.updateNav(index),
        onLeaveBack: () => this.updateNav(index - 1)
      })

      this.h2s.push(h2)

    })

  }

  addEvents() {
    const { asideNav } = this.DOM
    const navLinks = asideNav.querySelectorAll('a')
    this.navLinks = []
    this.onNavClick = this.onNavClick.bind(this)
    navLinks.forEach(link => {
      link.addEventListener('click', this.onNavClick)
      this.navLinks.push(link)
    })
  }

  updateNav(index) {
    const { asideNav } = this.DOM;
    const { navLinks } = this;

    const prevLink = asideNav.querySelector('.is--active')
    prevLink && prevLink.classList.remove('is--active')

    if (index >= 0) {
      navLinks[index].classList.add('is--active')
    }
  }

  onNavClick(e) {
    e.preventDefault()
    const { currentTarget } = e
    const targetAnchor = currentTarget.getAttribute('href')
    const { hasSmoothScroll, viewScroll } = viewStorage
    if (hasSmoothScroll) viewScroll.scroll.scrollTo(targetAnchor, { offset: -100, duration: 0.8, easing: (t) => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2 })
    else gsap.to(window, {duration: 1.8, scrollTo: { y: targetAnchor, offsetY: -100, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  }

  destroy() {
    if (isDEVMODE) console.log('Destroy SingleNav')
    this.navLinks && this.navLinks.forEach(link => link.removeEventListener('click', this.onNavClick))
    this.h2s && this.h2s.forEach(h2 => h2.ST.kill(true))
  }
}
