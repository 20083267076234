/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage } from './_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewModals from './modules/view-modal'
import Nav from './modules/single-nav'

/* --- DOMContentLoaded Function --- */
export const onReady = () => {

  const { body } = domStorage

  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(body)
  viewStorage.viewInView = new viewInView(body)
  viewStorage.viewModals = new viewModals(body)
  viewStorage.Nav = new Nav(body.querySelector('.Single__aside'))

  setTimeout(() => window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop), 100)
  ScrollTrigger.refresh()

  // Intro
  GlobalIntro()

  // Add Classs to body
  if (viewStorage.current.classList.contains('Home')) body.classList.add('--home')

}

export const onEnter = (to, trigger) => {

  // LoadingClasses
  const { body } = domStorage
  body.classList.remove('--loading')

  // ScrollTop
  window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  const { hasSmoothScroll } = viewStorage
  body.style.overflow = ''

  if (hasSmoothScroll) viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()
  viewStorage.viewModals = new viewModals()
  viewStorage.Nav = new Nav(viewStorage.current.querySelector('.Single__aside'))

  // Add Classs to body
  if (viewStorage.current.classList.contains('Home')) body.classList.add('--home')
}

export const onEnterCompleted = (to, from, trigger) => {

  if (isDEVMODE) console.log('onEnterCompleted')

  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  }

  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': targetLocation.href
    })

    console.log(globalStorage.tarteaucitron)
    console.log(globalStorage.tarteaucitron.user.gtagUa)
    console.log(targetLocation.pathname)
    console.log(to.page)
    console.log(targetLocation.href)
  }

}

export const onLeave = (from, trigger) => {

  // Remove Previous Page Effects
  const { hasInView, hasSticky, hasPrllx, hasModals, hasCursor, Nav } = viewStorage

  hasInView && viewStorage.viewInView.destroy()
  hasSticky && viewStorage.viewSticky.destroy()
  hasPrllx && viewStorage.viewPrllx.destroy()
  hasModals && viewStorage.viewModals.destroy()
  hasCursor && globalStorage.Cursor.removeHovers()
  Nav && viewStorage.Nav.destroy()

  // LoadingClasses
  const { body } = domStorage
  body.classList.add('--loading')
  body.classList.add('--animating')

  const { menu } = domStorage
  const { menuOpen } = globalStorage

  // Close Menu
  menuOpen && globalStorage.closeMobileMenu()

  // Remove active links
  const { targetLocation } = globalStorage.taxi
  const navLinks = menu.querySelectorAll('.Nav__link')
  navLinks.forEach((link) => {
    if (link.href === targetLocation.raw) link.classList.add('--active')
    else link.classList.remove('--active')
  })

  // Remove Classs to body
  if (viewStorage.current.classList.contains('Home')) body.classList.remove('--home')

}
